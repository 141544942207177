export function parameterize(string) {
    return string
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 -]/, '')
        .replace(/\s/g, '-');
}
export function urlOrigin(url) {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.origin;
    }
    catch (e) {
        return url;
    }
}
// https://stackoverflow.com/a/15458968
export function isHTML(str) {
    return /<\/?[a-z][\s\S]*>/i.test(str);
}
export function findFirstUrl(str) {
    return str?.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/)?.[0];
}
export function camelToDasherized(key) {
    return key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}
export const hexRegex = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;
export const camelize = (str) => str
    .toLowerCase()
    .replace(/_[a-z]/g, (match) => match.charAt(1).toUpperCase());
export const pascalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
